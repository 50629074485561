import { Box, Collapse, Skeleton, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import PlanCard from '../cards/PlanCard';
import { useUserStore } from '../../store/user';
import configureAxios from '../../axiosClient';
import LeftBillingSummary from './LeftBillingSummary';
import AccountStatusTracker from './AccountStatusTracker';
import CardManager from './CardManager';
import { usePlanStore } from '../../store/plan';
import { capitalize } from '../../utils/helper';
import { dispatchSubscriptionToAnalytics } from '../../analytics';
import { useAuthStore } from '../../store/auth';
import UserInvitesModal from '../modals/UserInvitesModal';
import { CloseIcon } from '@chakra-ui/icons';

const axiosClient = configureAxios();

type Plan = {
  planType: string;
  charges: string;
  btnText: string;
  targetPeople: string;
  planDuration: string;
};

interface AdditionalPlan {
  [key: string]: AdditionalPlanData;
}

interface AdditionalPlanData {
  planType: string;
  btnText: string;
  targetPeople: string;
}

interface FetchedPlan {
  id: string;
  version: number;
  planTemplateId: string;
  productId: string;
  name: string;
  code: string;
  standingCharge?: number;
  standingChargeDescription: string;
  bespoke: boolean;
  minimumSpend?: number;
  minimumSpendDescription: string;
  ordinal?: number;
  standingChargeBillInAdvance?: boolean;
  dtCreated: string;
  dtLastModified: string;
  createdBy: string;
  lastModifiedBy: string;
  planType: string;
  btnText: string;
  targetPeople: string;
}

function Plans() {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const {
    organisation,
    user,
    inactiveOrganisation,
    userCreateNewOrganisationView,
    setOrganisation,
    setUserCreateNewOrganisationView,
    getOrganizations,
    setInactiveOrganisation,
  } = useUserStore();
  const {
    suggestedPlan,
    currentPlan,
    inActiveOrgCurrentPlan,
    showPlans,
    setShowPlans,
    setSuggestedPlan,
    setCurrentPlan,
    setInActiveOrgCurrentPlan,
    setShowCancelPlans,
    setShowEnterpriseForm,
    setShowGettingStartedModal,
  } = usePlanStore();
  const { firstTime } = useAuthStore();
  const [billingPeriod, setBillingPeriod] = useState('monthly');
  const [view, setView] = useState('plans');
  const [submittingPlan, setSubmittingPlan] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan>({
    planType: '',
    charges: '',
    btnText: '',
    targetPeople: '',
    planDuration: '',
  });
  const [loadingPlans, setLoadingPlans] = useState<boolean>(true);
  const [refetchPlan, setReFetchPlan] = useState<boolean>(true);
  const [userInvitesModalActive, setUserInvitesModalActive] = useState(false);
  const [fetchedPlans, setFetchedPlans] = useState<FetchedPlan[]>([]);
  const [disabledPlans, setDisabledPlans] = useState<string[]>([]);

  const close = () => {
    setShowPlans(false);
    setView('plans');
    firstTime && setShowGettingStartedModal(true);
  };

  const filteredPlans = fetchedPlans.filter((plan) => {
    if (billingPeriod === 'monthly') {
      return plan.code.includes('_monthly') || plan.code.includes('free');
    } else {
      return plan.code.includes('_yearly') || plan.code.includes('free');
    }
  });

  const orderOfPlans = [
    'Free',
    'Pro_monthly',
    'Pro_yearly',
    'Studio_monthly',
    'Studio_yearly',
    'Enterprise_monthly',
    'Enterprise_yearly',
  ];

  filteredPlans.sort((a: FetchedPlan, b: FetchedPlan) => {
    return orderOfPlans.indexOf(a.name) - orderOfPlans.indexOf(b.name);
  });

  useEffect(() => {
    setLoadingPlans(true);
    axiosClient
      .get('user/occupied-plans')
      .then((res) => {
        setDisabledPlans(res?.data?.data);
        const message = `Organisations with ${res?.data?.data.join(
          ' and '
        )} plan can only be created once each. You cannot create multiple organizations with the same plan.`;
        axiosClient
          .get(`/plans`)
          .then((res) => {
            const isStatusOk = res.data.status === 'OK';
            if (res.data.status === 'LOGOUT') {
              console.log('Logged Out');
            } else if (isStatusOk) {
              setFetchedPlans(res.data.data.data as FetchedPlan[]);
              setLoadingPlans(false);
              if (showPlans && !firstTime) {
                toast({
                  title: message,
                  status: 'warning',
                  isClosable: false,
                });
              }
            } else {
              toast({
                title: `There was an error fetching all plans`,
                status: 'error',
              });
            }
          })
          .catch((err: unknown) => {
            console.log(err);
          });
      })
      .catch((err: unknown) => {
        console.log('Error -> ', err);
      });
  }, [inactiveOrganisation.organizationId, billingPeriod]);

  useEffect(() => {
    const userOrgId = inactiveOrganisation.organizationId
      ? inactiveOrganisation.organizationId
      : organisation.organizationId;
    organisation?.alias?.length > 0 &&
      axiosClient
        .get(`org/${userOrgId}/plan/suggest`)
        .then((res) => {
          const isStatusOk = res.data.status === 'OK';
          if (res.data.status === 'LOGOUT') {
            console.log('Logged Out');
          } else if (isStatusOk) {
            const plan = res?.data?.data?.name?.split('_')[0];
            const interval = res?.data?.data?.name?.split('_')[1];
            setSuggestedPlan(capitalize(plan), capitalize(interval));
          } else {
            toast({
              title: `There was an error fetching submit status plan`,
              status: 'error',
            });
          }
        })
        .catch((err: unknown) => {
          console.log(err);
        });

    organisation?.alias?.length > 0 &&
      axiosClient
        .get(`org/${userOrgId}/plan/read`)
        .then((res) => {
          const isStatusOk = res.data.status === 'OK';
          if (res.data.status === 'LOGOUT') {
            console.log('Logged Out');
          } else if (isStatusOk) {
            inactiveOrganisation.organizationId
              ? setInActiveOrgCurrentPlan(res.data.data)
              : setCurrentPlan(res.data.data);
          } else {
            toast({
              title: `There was an error fetching submit status plan`,
              status: 'error',
            });
          }
        })
        .catch((err: unknown) => {
          console.log(err);
        });
  }, [
    organisation.organizationId,
    inactiveOrganisation.organizationId,
    refetchPlan,
  ]);

  const payForPlan = () => {
    const userOrgId = inactiveOrganisation.organizationId
      ? inactiveOrganisation.organizationId
      : organisation.organizationId;
    setSubmittingPlan(true);
    axiosClient
      .get(
        `org/${userOrgId}/plan/update/${selectedPlan?.planType}/${billingPeriod}`
      )
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        if (isStatusOk) {
          setReFetchPlan(!refetchPlan);
          if (inactiveOrganisation.organizationId) {
            setOrganisation({
              organizationId: inactiveOrganisation.organizationId,
              alias: inactiveOrganisation.alias,
              roles: inactiveOrganisation.roles || 'member',
            });
            setInactiveOrganisation({
              organizationId: '',
              alias: '',
              roles: '',
            });
          }
          close();
          getOrganizations();
          if (
            userCreateNewOrganisationView &&
            selectedPlan.planType !== 'Pro' &&
            selectedPlan.planType !== 'Free'
          ) {
            setUserInvitesModalActive(true);
            setUserCreateNewOrganisationView(false);
          }

          if (selectedPlan.btnText === 'Start Free Trial') {
            dispatchSubscriptionToAnalytics('start_trial', {
              current_plan: selectedPlan.planType,
              previous_plan: currentPlan.name,
              subscription_price: selectedPlan.charges,
              organisation_id: organisation.organizationId,
              organisation_user_id: user.id,
              subscription_interval: billingPeriod,
            });
          } else {
            dispatchSubscriptionToAnalytics('purchase_subscription', {
              current_plan: selectedPlan.planType,
              previous_plan: currentPlan.name,
              subscription_price: selectedPlan.charges,
              organisation_id: organisation.organizationId,
              organisation_user_id: user.id,
              subscription_interval: billingPeriod,
            });
          }

          toast({
            title: `${selectedPlan?.planType} was subscribed successfully`,
            status: 'success',
          });
          setSubmittingPlan(false);
        } else {
          toast({
            title: res.data.data || `Error occurred while updating pan`,
            status: 'error',
          });
          setSubmittingPlan(false);
        }
      })
      .catch((err: any) => {
        console.log('error', err);
        toast({
          title: err.response.data.data,
          status: 'error',
        });
        setSubmittingPlan(false);
      });
  };

  const additionalPlanData: AdditionalPlan = {
    Free: {
      planType: 'Free',
      btnText: !currentPlan.name.includes('Free')
        ? 'Cancel Paid Plan'
        : 'Start For Free',
      targetPeople: 'for students & personal projects',
    },
    Pro: {
      planType: 'Pro',
      btnText: !currentPlan.name.includes('Free')
        ? 'Shift To Pro'
        : 'Start Free Trial',
      targetPeople: 'for Freelancers',
    },
    Studio: {
      planType: 'Studio',
      btnText: !currentPlan.name.includes('Free')
        ? 'Shift To Studio'
        : 'Start Free Trial',
      targetPeople: 'for Studios & Start-Ups',
    },
    Enterprise: {
      planType: 'Enterprise',
      btnText: 'Contact Sales',
      targetPeople: 'for retailers & large organisations',
    },
  };

  return (
    <Box
      opacity={showPlans ? 1 : 0}
      visibility={showPlans ? 'visible' : 'hidden'}
      transition='all 0.5s'
      position='fixed'
      height='100vh'
      overflow='auto'
      width='100%'
      background='rgba(8, 16, 29, 0.6)'
      backdropFilter='blur(30px)'
      zIndex='100'
      padding={['40px 0px']}
      color='white'
    >
      <Box display='flex' justifyContent='flex-end' padding='0 10px'>
        <CloseIcon
          boxSize={5}
          cursor='pointer'
          onClick={() => {
            close();
            setUserCreateNewOrganisationView(false);
            setInactiveOrganisation({
              organizationId: '',
              alias: '',
              roles: '',
            });
          }}
        />
      </Box>

      <Box
        overflow='auto'
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        alignItems='center'
        width='100%'
        gap={8}
      >
        <Collapse
          animateOpacity
          in={view === 'plans'}
          transition={{ enter: { delay: 0.5 } }}
        >
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
            gap={8}
          >
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignItems='center'
              gap={2}
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                gap={2}
                bg='#08101D'
                border='1px solid #F2F3F524'
                borderRadius='30px'
                padding='4px'
              >
                <Box
                  display='flex'
                  width='auto'
                  alignItems='center'
                  textAlign='center'
                  padding='12px 24px'
                  color={billingPeriod === 'yearly' ? 'white' : '#7F8B9E'}
                  bg={billingPeriod === 'yearly' ? '#1D2531' : 'transparent'}
                  borderRadius='30px'
                  transition='0.2s'
                  cursor='pointer'
                  gap={2}
                  _hover={{
                    bg: '#1D2531',
                    color: 'white',
                  }}
                  onClick={() => setBillingPeriod('yearly')}
                >
                  <Text fontSize='14px' fontFamily='Metal'>
                    YEARLY
                  </Text>
                  <Text
                    fontSize='14px'
                    fontFamily='Metal'
                    color='#D3BB8A'
                    textDecoration='uppercase'
                  >
                    Save 25%
                  </Text>
                </Box>
                <Box
                  width='120px'
                  textAlign='center'
                  padding='12px 24px'
                  color={billingPeriod === 'monthly' ? 'white' : '#7F8B9E'}
                  bg={billingPeriod === 'monthly' ? '#1D2531' : 'transparent'}
                  borderRadius='30px'
                  transition='0.2s'
                  cursor='pointer'
                  _hover={{
                    bg: '#1D2531',
                    color: 'white',
                  }}
                  onClick={() => setBillingPeriod('monthly')}
                >
                  <Text fontSize='14px' fontFamily='Metal'>
                    MONTHLY
                  </Text>
                </Box>
              </Box>
              <Box>
                <Text fontSize='12px' fontFamily='Normal'>
                  All prices are in USD and charged per organization account
                  with applicable taxed added at checkout
                </Text>
              </Box>
            </Box>

            {loadingPlans ? (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='flex-start'
                gap={3}
              >
                <Skeleton
                  width={['340px']}
                  startColor='#29303C'
                  endColor='transparent'
                  isLoaded={!loadingPlans}
                  borderRadius='30px'
                  height='400px'
                />
                <Skeleton
                  width={['340px']}
                  startColor='#29303C'
                  endColor='transparent'
                  isLoaded={!loadingPlans}
                  borderRadius='30px'
                  height='500px'
                />
                <Skeleton
                  width={['340px']}
                  startColor='#29303C'
                  endColor='transparent'
                  isLoaded={!loadingPlans}
                  borderRadius='30px'
                  height='800px'
                />
                <Skeleton
                  width={['340px']}
                  startColor='#29303C'
                  endColor='transparent'
                  isLoaded={!loadingPlans}
                  borderRadius='30px'
                  height='600'
                />
              </Box>
            ) : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='flex-start'
                gap={5}
              >
                {filteredPlans.map((plan: FetchedPlan) => {
                  const planName = plan.name.split('_')[0];
                  const durationText =
                    planName === 'Free'
                      ? ''
                      : planName === 'Enterprise'
                      ? ''
                      : `/ monthly`;
                  const duration =
                    planName === 'Free'
                      ? ''
                      : planName === 'Enterprise'
                      ? ''
                      : `/ ${plan?.code?.split('_')[1]}`;
                  const charges =
                    planName === 'Free'
                      ? 'No Charges'
                      : planName === 'Enterprise'
                      ? 'Custom Pricing'
                      : `$${plan?.standingCharge?.toString()}.00`;
                  const isDisabled = disabledPlans?.includes(
                    planName.toLowerCase()
                  );
                  return (
                    <PlanCard
                      key={planName}
                      current={
                        inactiveOrganisation.organizationId
                          ? planName ===
                            inActiveOrgCurrentPlan?.name?.split('_')[0]
                          : planName === currentPlan?.name?.split('_')[0]
                      }
                      suggested={planName === suggestedPlan.plan}
                      planType={planName}
                      isDisabled={isDisabled}
                      targetPeople={additionalPlanData[planName]?.targetPeople}
                      charges={charges}
                      btnText={additionalPlanData[planName]?.btnText}
                      planDuration={durationText}
                      onClick={() => {
                        setSelectedPlan({
                          planType: planName,
                          charges: plan?.standingCharge?.toString() || '',
                          btnText: additionalPlanData[planName]?.btnText,
                          targetPeople:
                            additionalPlanData[planName]?.targetPeople,
                          planDuration: duration,
                        });

                        if (planName === 'Free') {
                          if (!currentPlan.name.includes('Free')) {
                            setShowCancelPlans(true);
                          } else {
                            close();
                          }
                        } else if (
                          planName === 'Pro' ||
                          planName === 'Studio'
                        ) {
                          setView('payment');
                        } else if (planName === 'Enterprise') {
                          setShowEnterpriseForm(true);
                        }
                      }}
                      additionalClick={() => setShowEnterpriseForm(true)}
                    />
                  );
                })}
              </Box>
            )}
          </Box>
        </Collapse>

        <Collapse
          animateOpacity
          in={view === 'payment'}
          transition={{ enter: { delay: 0.5 } }}
        >
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            alignItems='center'
            gap={8}
          >
            <AccountStatusTracker setView={setView} />

            <Box
              display='flex'
              justifyContent='center'
              alignItems='flex-start'
              gap={5}
            >
              <Box
                minW={['700px']}
                padding={['20px']}
                borderRadius='16px'
                boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
                bg='white'
              >
                {view === 'payment' && <CardManager />}
              </Box>
              <LeftBillingSummary
                selectedPlan={selectedPlan}
                submittingPlan={submittingPlan}
                billingPeriod={billingPeriod}
                btnText={
                  additionalPlanData[
                    selectedPlan?.planType as keyof typeof additionalPlanData
                  ]?.btnText
                }
                payForPlan={() => payForPlan()}
              />
            </Box>
          </Box>
        </Collapse>
      </Box>

      <UserInvitesModal
        userInvitesModalActive={userInvitesModalActive}
        setUserInvitesModalActive={setUserInvitesModalActive}
      />
    </Box>
  );
}

export default Plans;
