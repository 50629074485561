import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import configureAxios from '../../axiosClient';
import { useUserStore } from '../../store/user';

type Invoice = {
  id: string;
  name: string;
  due_date: number;
  paid: boolean;
  total: number;
  url: string;
};

interface BillingHistoryTableProps {
  invoices: Invoice[];
  setIsPaid: (status: boolean) => void;
}

function BillingHistoryTable({
  invoices,
  setIsPaid,
}: BillingHistoryTableProps) {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const axiosClient = configureAxios();
  const organisation = useUserStore((state) => state.organisation);

  const payInvoice = (invoiceId: string) => {
    const userOrgId = organisation.organizationId;
    axiosClient
      .post(`org/${userOrgId}/invoice/pay`, {
        invoice_id: invoiceId,
      })
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        if (isStatusOk) {
          setIsPaid(true);
          toast({
            title: `${res.data.data.message}`,
            status: 'success',
          });
          setIsPaid(false);
        } else {
          toast({
            title: `${res.data.data.message}`,
            status: 'error',
          });
        }
      });
  };

  return (
    <TableContainer mt={['40px']}>
      <Table variant='simple' size='lg'>
        <Thead height={['auto']}>
          <Tr>
            <Th
              width={['400px']}
              borderBottom='2px solid #D3BB8A'
              padding={['16px']}
            >
              <Text
                fontFamily='Normal'
                textTransform='capitalize'
                fontSize={['15px']}
                color='#D3BB8A'
              >
                Billing History
              </Text>
            </Th>
            <Th borderBottom='2px solid #D3BB8A' padding={['16px']}>
              <Text
                fontFamily='Normal'
                textTransform='capitalize'
                fontSize={['15px']}
                textAlign='center'
              >
                date
              </Text>
            </Th>
            <Th borderBottom='2px solid #D3BB8A' padding={['16px']}>
              <Text
                fontFamily='Normal'
                textTransform='capitalize'
                fontSize={['15px']}
                textAlign='center'
              >
                Invoice Total
              </Text>
            </Th>
            <Th borderBottom='2px solid #D3BB8A' padding={['16px']}>
              <Text
                fontFamily='Normal'
                textTransform='capitalize'
                fontSize={['15px']}
                textAlign='center'
              >
                Status
              </Text>
            </Th>
            <Th
              borderBottom='2px solid #D3BB8A'
              padding={['16px']}
              textAlign='center'
            >
              <Text
                fontFamily='Normal'
                textTransform='capitalize'
                fontSize={['15px']}
              >
                Download
              </Text>
            </Th>
            <Th
              borderBottom='2px solid #D3BB8A'
              padding={['16px']}
              textAlign='center'
            >
              <Text
                fontFamily='Normal'
                textTransform='capitalize'
                fontSize={['15px']}
              >
                Action
              </Text>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {invoices.map((invoice: Invoice, index: number) => (
            <Tr key={index} padding={['16px']} height={['60px']}>
              <Td padding={['16px']}>
                <Text fontSize={['14px']} fontFamily='Normal'>
                  {invoice.name}
                </Text>
              </Td>
              <Td padding={['16px']} textAlign='center'>
                <Text fontFamily='Normal' fontSize={['14px']}>
                  {invoice.due_date}
                </Text>
              </Td>
              <Td padding={['16px']} textAlign='center'>
                <Text fontFamily='Normal' fontSize={['14px']}>
                  {`$${invoice.total}`}
                </Text>
              </Td>
              <Td padding={['16px']} textAlign='center'>
                <Text fontFamily='Normal' fontSize={['14px']}>
                  {invoice.total === 0 || (invoice.total > 0 && invoice.paid)
                    ? 'Paid'
                    : 'Pending'}
                </Text>
              </Td>
              <Td textAlign='center'>
                <Button
                  variant='link'
                  fontFamily='Metal'
                  fontSize={['15px']}
                  color='white'
                  onClick={() => window.open(invoice.url, '_self')}
                >
                  Download
                </Button>
              </Td>
              <Td textAlign='center'>
                {!invoice.paid && invoice.total !== 0 ? (
                  <Button
                    variant='link'
                    fontFamily='Metal'
                    fontSize={['15px']}
                    color='white'
                    onClick={() => payInvoice(invoice.id)}
                  >
                    Pay Now
                  </Button>
                ) : (
                  <Text
                    fontFamily='Normal'
                    fontSize={['14px']}
                    textAlign='center'
                  >
                    --
                  </Text>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {invoices.length === 0 && (
        <Box
          height={['60px']}
          width={['100%']}
          display='flex'
          justifyContent='center'
          alignItems='center'
          fontFamily='Normal'
          fontSize={['14px']}
        >
          No Invoices Yet
        </Box>
      )}
    </TableContainer>
  );
}

export default BillingHistoryTable;
