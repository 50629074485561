/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Divider,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { Settings } from '../../Icons/Settings';
import { NavLink, useNavigate } from 'react-router-dom';
import { useUserStore } from '../../store/user';
import DummyUser from '../../assets/image/DummyUser.png';
import { useAuthStore } from '../../store/auth';
import useRoleAuthorization from '../../utils/useRoleAuthorization';
import React, { useState } from 'react';
import configureAxios from '../../axiosClient';
import { useExperiencesStore } from '../../store/experiences';
import { usePlanStore } from '../../store/plan';
import { Selected } from '../../Icons/Selected';

const axiosClient = configureAxios();

function UserDropdown() {
  const navigate = useNavigate();
  const hasAccess = useRoleAuthorization();
  const { resetPlans, setShowPlans } = usePlanStore();
  const { resetExperiences } = useExperiencesStore();
  const {
    user,
    roles,
    resetUser,
    organisation,
    organisationList,
    getOrganizations,
    setOrganisation,
    setInactiveOrganisation,
  } = useUserStore();
  const { resetAuth } = useAuthStore();

  const [selectedOrganisationLoader, setSelectedOrganisationLoader] = useState<
    string | null
  >(null);

  const logOut = () => {
    resetAuth();
    resetUser();
    resetExperiences();
    resetPlans();

    axiosClient
      .get(`auth/logout`)
      .then(() => {
        console.log('logged out');
      })
      .catch((err: unknown) => {
        console.log(err);
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectOrganisation = (org: any) => {
    setSelectedOrganisationLoader(org.org_id);
    axiosClient
      .put(`user/default-organisation/${org.org_id}`)
      .then((res) => {
        if (res.data.status === 'OK') {
          const newOrganisation = {
            organizationId: res.data.data.organisation,
            alias: org.alias,
            roles: org.roles,
          };
          setOrganisation(newOrganisation);
          getOrganizations();
          setTimeout(() => {
            setSelectedOrganisationLoader(null);
          }, 2000);
        }
      })
      .catch((err: unknown) => {
        console.log(err);
        setSelectedOrganisationLoader(null);
      });
  };

  const handleInActiveOrganisation = (org: any) => {
    const inActiveOrg = {
      organizationId: org.org_id,
      alias: org.alias,
      roles: org.roles,
    };
    setInactiveOrganisation(inActiveOrg);
    setShowPlans(true);
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <Popover
      placement='bottom-end'
      returnFocusOnClose={false}
      closeOnBlur={true}
    >
      <PopoverTrigger>
        <Image
          loading='lazy'
          src={DummyUser}
          height={['35px']}
          width={['35px']}
          borderRadius='100%'
          cursor='pointer'
        />
      </PopoverTrigger>
      <PopoverContent
        borderRadius='16px'
        background='#FFF'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
        backdropFilter='blur(12px)'
        width={['350px']}
        height={['auto']}
        maxHeight='700px'
        overflowY='auto'
      >
        <PopoverBody
          height={['inherit']}
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          padding={['20px']}
        >
          <Box
            borderRadius='16px'
            border='1px solid #D6DBE4'
            boxShadow='0px 2px 4px 0px rgba(0, 0, 0, 0.25)'
            padding={['15px']}
            width='100%'
          >
            <Box
              display='flex'
              justifyContent='flex-start'
              alignItems='center'
              gap={2}
            >
              <Image
                src={DummyUser}
                height={['40px']}
                width={['40px']}
                borderRadius='100%'
                cursor='pointer'
              />

              <Box flex={1} overflow='hidden'>
                <Box
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  width={['inherit']}
                  gap={2}
                >
                  <Text color='#08101D' fontFamily='Normal' fontSize={['15px']}>
                    {user.firstName + ' ' + user.lastName}
                  </Text>
                  <Text color='#08101D' fontFamily='Normal' fontSize={['11px']}>
                    {roles?.includes('owner')
                      ? 'Owner'
                      : roles?.includes('admin')
                      ? 'Admin'
                      : roles?.includes('member') && ' Member'}
                  </Text>
                </Box>

                <Text
                  color='#08101D'
                  fontFamily='Normal'
                  fontSize={['14px']}
                  width='90%'
                  display='block'
                  textOverflow='ellipsis'
                  whiteSpace='nowrap'
                  overflow='hidden'
                  title={user.email}
                >
                  {user.email}
                </Text>
              </Box>
            </Box>
          </Box>

          <Box mt='16px' flexDir='column' gap={5}>
            <Text fontWeight='400' color='#08101D' mb='8px'>
              Teams
            </Text>
            <Box display='flex' flexDirection='column' gap={5}>
              {organisationList?.map((org: any, index: number) => {
                return (
                  <Box
                    key={index}
                    borderRadius='16px'
                    border={
                      org.org_id === organisation.organizationId
                        ? '1px solid #D3BB8A'
                        : '1px solid #D6DBE4'
                    }
                    boxShadow='0px 2px 4px 0px rgba(0, 0, 0, 0.25)'
                    padding={['15px']}
                    cursor='pointer'
                    _hover={{
                      border: '1px solid #D3BB8A',
                    }}
                    onClick={() => {
                      if (
                        org.org_id !== organisation.organizationId &&
                        org.is_active === true
                      ) {
                        selectOrganisation(org);
                      } else if (org.is_active === false) {
                        handleInActiveOrganisation(org);
                      }
                    }}
                  >
                    {selectedOrganisationLoader === org.org_id ? (
                      <Box display='flex' justifyContent='center'>
                        <Spinner size='md' color='#08101D' />
                      </Box>
                    ) : (
                      <Box
                        display='flex'
                        justifyContent='flex-start'
                        alignItems='center'
                        gap={1}
                      >
                        <Image
                          src={DummyUser}
                          height={['40px']}
                          width={['40px']}
                          borderRadius='100%'
                          cursor='pointer'
                          mr={['10px']}
                        />

                        <Box width={['auto']}>
                          <Box
                            display='flex'
                            justifyContent='flex-start'
                            alignItems='center'
                            width={['inherit']}
                          >
                            <Text
                              color='#08101D'
                              fontFamily='Normal'
                              fontSize={['15px']}
                              width='150px'
                              display='block'
                              textOverflow='ellipsis'
                              whiteSpace='nowrap'
                              overflow='hidden'
                              title={org.alias}
                            >
                              {org.alias}
                            </Text>
                          </Box>
                          <Box
                            display='flex'
                            justifyContent='flex-start'
                            alignItems='center'
                            gap={2}
                          >
                            <Box
                              display='flex'
                              justifyContent='space-between'
                              alignItems='center'
                              gap={2}
                            >
                              <Text
                                color='#08101D'
                                fontFamily='Normal'
                                fontSize={['14px']}
                                width={['inherit']}
                              >
                                {capitalizeFirstLetter(
                                  org?.plan?.split('_')[0]
                                )}
                              </Text>
                              {!org?.is_active && (
                                <Text
                                  fontSize='14px'
                                  color='#FA243B'
                                  fontWeight='600'
                                  fontFamily='metal'
                                  letterSpacing='1px'
                                >
                                  Inactive
                                </Text>
                              )}
                            </Box>
                            <Divider
                              orientation='vertical'
                              height='20px'
                              color='#D6DBE4'
                            />
                            <Text
                              color='#08101D'
                              fontFamily='Normal'
                              fontSize={['14px']}
                              width={['inherit']}
                            >
                              Members {org.member_count}
                            </Text>
                          </Box>
                        </Box>
                        <Box margin='auto'>
                          {org.org_id === organisation.organizationId && (
                            <Selected boxSize={[5]} />
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>

          <Box
            display='flex'
            justifyContent={hasAccess ? 'space-between' : 'flex-end'}
            alignItems='center'
            mt={['20px']}
          >
            {hasAccess && (
              <NavLink to='/billing' style={{ height: 'auto' }}>
                <Button
                  variant='link'
                  leftIcon={<Settings boxSize={[4]} />}
                  color='#08101D'
                  fontFamily='Normal'
                  fontSize={['11px']}
                >
                  Organization Settings
                </Button>
              </NavLink>
            )}

            <Button
              variant='outline'
              color='#08101D'
              fontFamily='Normal'
              fontSize={['11px']}
              borderRadius='30px'
              border='1px solid #08101D'
              height={['30px']}
              onClick={() => {
                navigate('/login');
                logOut();
              }}
            >
              Log out
            </Button>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default UserDropdown;
